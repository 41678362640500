<template>
  <div>
    <b-modal
      id="forgot-password"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('add1')"
      no-close-on-backdrop
      size="m"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="$t('add2')"
          label-for="input-1"
        >
          <b-form-input
            id="email"
            v-model="email"
            type="email"
            class="form-control1"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t("add3") }}</b-form-invalid-feedback>
        </b-form-group>
        <pre />
        <div
          style="
        text-align: left;
    display: flex;
    height: 49px;
    justify-content: unset;
    align-items: self-end;
    align-self: end;"
        >
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 5px"
            @click="onCancel"
          >
            {{ $t("add4") }}
          </b-button>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="submit"
          >
            {{ $t("add5") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
// import {
//   required, email,
// } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex'

export default {
  components: {},
  data() {
    return {
      email: '',
    };
  },
  // validations: {
  //   form: {
  //     email: {
  //       required,
  //       email,
  //     },
  //   },
  // },

  methods: {
    ...mapActions(['loadForgetPassword']),
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },
    submit() {
      // this.$emit('assignManager', this.form);
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      // this.loadForgetPassword(this.form.email)
      this.$emit('ee', this.email)
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.email = ''
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  // display: grid;
  width: 60%;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
