<template>
  <div class="login-page">
    <Signup />
  </div>
</template>

<script>
import Signup from '@/components/Signup.vue'

export default {

  name: 'MyComponent',
  components: {
    Signup,
  },
  data() {
    return {
      apiKeys: [],
    }
  },
  computed: {
    shouldShowSidebar() {
      return this.$route.meta.sidebar !== false;
    },
    taskBoards() {
      return this.$store.state.articles
    },
  },
  mounted() {
    this.fetchApikeys()
  },
  methods: {
    async fetchApikeys() {
      this.loading = true
      // await this.$store.dispatch('articles/fetchReadApiKeys', this.apiKeys)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
