<template>
  <div>
    <b-modal
      id="reset-password"
      ref="modal"
      v-model="show"
      header-class="headerModal"
      centered
      :title="$t('add11')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <div
          style="display: flex;
    flex-direction: column;"
        >
          <img
            src="../../../assets/gif-resetpass (2).gif"
            alt=""
          >
          <!-- <pre /> -->
          <div style="display: block; ">
            <b-form-group
              id="input-group-1"
              :label="$t('add12')"
              label-for="input-1"
            >
              <!-- <b-form-input
                id="input-1"
                v-model="$v.form.password1.$model"
                :state="validateState('password1')"
                aria-describedby="input-1-live-feedback"
              /> -->
              <b-form-input
                id="input-2"
                v-model="$v.form.password1.$model"
                :state="validateState('password1')"
                :type="showPassword ? 'text' : 'password'"
                class="form-control"
                aria-describedby="input-1-live-feedback"
                @keyup.enter="onSubmit"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="$t('add13')"
              label-for="input-1"
              style="margin-top: 10px"
            >
              <!-- <b-form-input
                id="input-1"
                v-model="$v.form.password2.$model"
                :state="validateState('password2')"
                aria-describedby="input-1-live-feedback"
              /> -->
              <b-form-input
                id="input-2"
                v-model="$v.form.password2.$model"
                :state="validateState('password2')"
                :type="showPassword ? 'text' : 'password'"
                class="form-control"
                aria-describedby="input-1-live-feedback"
                @keyup.enter="onSubmit"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>

            <div style="margin-top: 10px">
              <b-button
                type="button"
                variant="danger"
                class="buttonCancel"
                style="margin-right: 15px"
                @click="onCancel"
              >
                {{ $t("Cancel") }}
              </b-button>
              <b-button
                type="button"
                variant="none"
                class="buttonSubmit"
                @click="onSubmit"
              >
                {{ $t("Submit") }}
              </b-button>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  required,
  alphaNum,
  minLength,
} from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';


export default {
  components: {},
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      form: {
        password1: null,
        password2: null,
      },
      show: false,
    };
  },
  validations: {
    form: {
      password1: {
        required,
        alphaNum,
        minLength: minLength(8),
      },
      password2: {
        required,
        alphaNum,
        minLength: minLength(8),
        matchPassword(value) {
          return value === this.form.password1;
        },
      },
    },
    // Custom validation message
    $v: {
      form: {
        password2: {
          matchPassword: 'Passwords do not match',
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getfreeTeamLeaders', 'getVacationRequests']),

  },
  watch: {
    showModal(value) {
      this.show = value
    },
  },
  methods: {
    ...mapActions(['loadUsersFullNamesById', 'cancelVacation']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('click', this.form.password1);
      this.$refs.modal.hide()
      this.onReset();
      this.show = false
      this.$router.push('/login');
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.password1 = '';
      this.form.password2 = '';
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  // display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
