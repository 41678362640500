<template>
  <section class="register">
    <div class="container">
      <div class="row">
        <div class="regiester-wrapper">
          <div class="register-content">
            <div
              class="logo"
              style="width: 500px; display: flex; justify-content: flex-end; "
            >
              <b-img
                :src="require('../assets/images/LogoBlue.png')"
                alt="Named color image (red)"
                width="500px"
                height="400px"
              />
            </div>
            <h1 style="color:#242F6E; font-size: 40px; font-weight: 100;">
              {{ $t('New Password') }}
            </h1>

            <b-form
              class=""
            >
              <!-- <b-form-group
                id="input-group-1"
                :label="$t('Email')"
                label-for="input-1"
                style="width: 55%"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.login.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('RequiredField') }}</b-form-invalid-feedback>
              </b-form-group> -->

              <b-form-group
                id="input-group-2"
                :label="$t('New Password')"
                label-for="input-1"
              >
                <div class="input-group mb-3">
                  <b-form-input
                    id="input-2"
                    v-model="$v.login.password.$model"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control"
                    :state="validateState('password')"
                    aria-describedby="input-1-live-feedback"
                    @keyup.enter="onSubmit"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      style="border-radius:0 5px 5px 0px"
                      @click="showPassword = !showPassword"
                    >
                      <span><i
                        class="fa"
                        :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                        aria-hidden="true"
                      />
                      </span>
                    </span>
                  </div>
                </div>
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('RequiredField') }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group />
              <b-form-group
                id="input-group-2"
                :label="$t('Confirm Password')"
                label-for="input-1"
              >
                <div class="input-group mb-3">
                  <b-form-input
                    id="input-2"
                    v-model="login.password2"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control"
                    aria-describedby="input-1-live-feedback"
                    @keyup.enter="onSubmit"
                  />
                  <!-- :state="validateState('password')" -->
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      style="border-radius:0 5px 5px 0px"
                      @click="showPassword1 = !showPassword1"
                    >
                      <span><i
                        class="fa"
                        :class="showPassword1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                        aria-hidden="true"
                      />
                      </span>
                    </span>
                  </div>
                </div>
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('RequiredField') }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group />
            </b-form>
            <!-- <div
              class="error"
              :style="isLogged == false && getIsLoggedIn == false ? 'justify-content: space-between' : 'justify-content: flex-end'"
            >
              <p
                v-if="isLogged == false && getIsLoggedIn == false"
                style="color:red"
                class="test"
              >
                {{ $t('Email_Or_Password_Is_Incorrect') }}
              </p>
              <p
                v-b-modal.forgot-password
                class="register-content__forgot-password"
                @click="openModal"
              >
                {{ $t('ForgotPassword') }}?
              </p>
            </div> -->
            <div>
              <!-- padding: 6px 48%; border-radius:6px -->
              <b-button
                style="border-radius:6px; width: 100%"
                type="submit"
                variant="none"
                class="buttonSubmit"
                @click="onSubmit"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                />
                <div v-if="loading == false">
                  {{ $t('Reset Password') }}
                </div>
              </b-button>
              <!-- <button
                type="submit"
                class="btn btn-primary"
                @click="loadingg(), onSubmit "
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                />Sign In</button> -->
              <LoadingButton
                :is-loading="isLoading"
                @click="onSubmit"
              />
            </div>

          </div>
          <div
            class="register-image"
          />
        </div>
      </div>
    </div>
    <ForgotPasswordModal @ee="forgetPassport" />
    <ChangeFirstPasswordModal
      @changeP="changePass"
    />
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    >
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required, email,
} from 'vuelidate/lib/validators';
// import * as signalR from '@microsoft/signalr'
import moment from 'moment-timezone';
// import ForgotPasswordModal from './login/modals/ForgotPasswordModal.vue'
// import ChangeFirstPasswordModal from './login/modals/ChangeFirstPasswordModal.vue'


export default {
  components: {
    // ForgotPasswordModal,
    // ChangeFirstPasswordModal,
  },

  mixins: [validationMixin],
  data() {
    return {
      showPassword: false,
      showPassword1: false,
      tokenUrl: null,
      show: true,
      isLogged: true,
      loading: false,
      login: {
        email: '',
        password: '',
        password2: '',
        date: moment.utc().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      },
    }
  },
  validations: {
    login: {
      email: {
        required,
        email,
      },
      password: {
        required,
        // password,
      },
    },
  },
  computed: {
    ...mapGetters(['getIsLoggedIn', 'getLoggedInUser']),
  },
  created() {
    // console.log('ResetPassword created');
  },
  mounted() {
    // Get the URL query parameters
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    // Access the token value from the query parameters
    const token = params.token;
    this.tokenUrl = token
    // console.log('eii', token); // Output: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySW...
  },
  methods: {
    ...mapActions(['attemptLogin', 'changePasswordForUser', 'loadForgetPassword', 'loadResetPassword']),

    validateState(name) {
      const { $dirty, $error } = this.$v.login[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.loadResetPassword({
        token: this.tokenUrl,
        newPassword: this.login.password,
      })
    },

    // onSubmit() {
    //   this.$v.login.$touch();
    //   if (this.$v.login.$anyError) {
    //     return;
    //   }

    //   // eslint-disable-next-line no-new
    //   // const myPromise = new Promise(() => {
    //   this.attemptLogin({
    //     object: this.login,
    //     successCallback: () => {
    //       this.isLogged = true
    //       this.loading = true
    //       if (this.getLoggedInUser.isFirstLoginExecuted == false) {
    //         this.$bvModal.show('modal-change-first-password');
    //       } else {
    //         this.$router.push('/');
    //       }
    //     },
    //   })
    //   // })

    //   // myPromise.then(
    //   //   () => {
    //   // const connection = new signalR.HubConnectionBuilder()
    //   //   .withUrl('https://stockmanagement.digitwebsite.ch/NotificationUserHub', {
    //   //     accessTokenFactory: () => {
    //   //       return this.getLoggedInUser.token;
    //   //     },
    //   //   })
    //   //   .configureLogging(signalR.LogLevel.Information)
    //   //   .build();

    //   // try {
    //   //   connection.start();
    //   //   console.log('SignalR Connected.');
    //   // } catch (err) {
    //   //   // setTimeout(start, 7000);
    //   // }
    //   // connection.start()
    //   // },
    //   // )
    //   setTimeout(() => {
    //     if (this.getIsLoggedIn == false) {
    //       this.isLogged = false
    //       this.login.email = ''
    //       this.login.password = ''
    //       setTimeout(() => { this.$v.$reset() }, 0)
    //       this.$nextTick(() => { this.$v.$reset() })
    //     }
    //   }, 1000)

    //   this.loading = !false
    //   setTimeout(() => {
    //     this.loading = !true
    //   }, 1000)
    // },
    forgetPassport(value) {
      this.loadForgetPassword({ email: value })
    },
    changePass(obj) {
      this.changePasswordForUser({
        object: obj,
        successCallback: () => {
          this.$router.push('/');
        },
      })
    },
    openModal() {
      this.$bvModal.show('forgot-password');
    },
    showL() {
      this.show = true;
    },
    showR() {
      this.show = false;
    },
  },
}
</script>

<style scoped lang="scss">
.fa{
  color: $base-color;
}
.error{
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 1400px) {
  .logo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  img{
    width: 500px;
  }
}
/* .buttonSubmit{
display: flex;
} */
input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
@media screen and (max-width: 1041px) {
.container {
  width: 100%;
  margin: 0 !important;
  max-width: inherit !important;
  padding: 0;
  .row {
    margin-right: 0;
    .regiester-wrapper {
      flex-direction: column-reverse;
      padding: 0;
      align-items: center;
      padding-top: 60px;
      .register-content {
    margin-right: 0px;
    max-width: 500px;
    min-width: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: auto;
    margin-bottom: 100px;
    .logo {
      img {
        margin-bottom: 0;
        height: 350px;
      }
    }
      }
      .register-image {
        height: 300px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
   }
  }
}
}
</style>
